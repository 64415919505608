@font-face {
  font-family: GiraSans;
  src: url("/fonts/GiraSansBook.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: GiraSans;
  src: url("/fonts/GiraSansMedium.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: NegroBold;
  src: url("/fonts/NegroBold.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}

$font-family-sans-serif: "GiraSans";
$rubin-beige: #fcefe8;
$rubin-red: #e30513;

html {
  scroll-behavior: smooth;
}

body {
  background-color: $rubin-beige !important;
}

h2 {
  font-size: 1.2em;
  font-weight: bold;
}

h3 {
  font-size: 1.6em;
  font-weight: normal;
}

a {
  color: #000;
  text-decoration: underline;
}

.rubinFooter {
  background-color: #e30613;
  padding-top: 1em;
  padding-bottom: 2em;
  margin-top: 1em;
}

.rubinFooter h4 {
  font-size: 1em;
  color: #fff;
  font-weight: bold;
}

.rubinFooter ul {
  list-style: none;
  padding: 0;
  margin: 1em 0 0 0;
}

.rubinFooter a {
  color: #fff;
  text-decoration: none;
}
.rubinFooter a:hover {
  color: #fff;
  text-decoration: underline;
}

.rubinPartners {
  margin-top: 1em;
  margin-bottom: 1em;
}

.rubinPartners p {
  margin-top: 1em;
}

.rubinPracticalInformations {
  margin-top: 3em;
  font-size: 0.9em;
}

.rubinPracticalInformations h3 {
  font-size: 1.6em;
}

.rubinPracticalInformations a {
  text-decoration: underline;
  color: #000;
}

.rubinPracticalInformations ul li {
  position: relative;
}

.rubinPracticalInformations ul {
  list-style-type: none;
  padding-left: 1.2em;
}

.rubinPracticalInformations ul li:before {
  content: "—";
  position: absolute;
  left: -1.2em;
  top: 0;
}

.rubinPracticalInformations p strong {
  font-size: 1.4em;
}

.repertoar-item {
  margin-bottom: 2rem;
}

.repertoar-month {
  margin-bottom: 4rem;
}

.rubin-jumbotron {
  margin-bottom: 4rem;
}

.itemTitle {
  font-family: "NegroBold";
  font-size: 1.9rem !important;
  position: relative;
}

.itemTitle--kind__premiere:before {
  content: "";
  background-image: url("../assets/layout/label-premiera.svg");
  background-repeat: no-repeat;
  width: 15rem;
  height: 4rem;
  position: absolute;
  left: -1rem;
  top: -0.8rem;
  background-size: 15rem;
}

.itemTitle--kind__derniere:after {
  content: "";
  background-image: url("../assets/layout/label-derniera.svg");
  background-repeat: no-repeat;
  width: 1.9rem;
  height: 4rem;
  position: absolute;
  left: -0.6rem;
  top: -0.1rem;
  background-size: 1.9rem;
}

/* .itemTitle--kind__guest:after {
  content: "";
  background-image: url("../assets/layout/label-host.svg");
  background-repeat: no-repeat;
  width: 1.2rem;
  height: 4rem;
  position: absolute;
  left: -1.4rem;
  top: 0.3rem;
  background-size: 1.2rem;
} */

.itemTitle--category__concert:after {
  content: "";
  background-image: url("../assets/layout/label-koncert.svg");
  background-repeat: no-repeat;
  width: 13rem;
  height: 4rem;
  position: absolute;
  left: -1.2rem;
  top: -1.5rem;
  background-size: 13rem;
}

.itemTitle a {
  color: #000;
  text-decoration: none;
}

.itemTitle a:hover {
  color: #000;
  text-decoration: none;
}

.itemDate p {
  margin-bottom: 0.3rem;
}

.item--date {
  font-weight: bold;
  font-size: 1.4rem;
}

.item--time {
  font-size: 1.2rem;
}

.item--kind {
  font-weight: bold;
  font-size: 0.8rem;
}

.itemDetail p {
  margin-bottom: 0;
  font-size: 0.9rem;
  position: relative;
}

.item--button {
  text-align: right;
}

.item--button a {
  display: inline-block;
  color: transparent;
  text-decoration: none;
  background-image: url("../assets/layout/vstupenky.svg");
  background-repeat: no-repeat;
  background-size: 7rem;
  width: 7rem;
  height: 1.8rem;
}

.item--button a:hover {
  color: transparent;
  text-decoration: none;
  background-image: url("../assets/layout/vstupenky-hover.svg");
}

.item--button span {
  font-weight: bold;
}

.navbar {
  background-color: $rubin-beige !important;
  padding: 1em 0 !important;
}

.rubin-link-to-program:hover {
  background-image: url("../assets/layout/hover-decoration-program.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 6rem;
}

.rubin-link-to-theatre:hover {
  background-image: url("../assets/layout/hover-decoration-theatre.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 6rem;
}

.inner-navigation,
.inner-navigation p,
.inner-navigation p strong,
.inner-navigation a {
  font-size: 1em;
  color: #000;
  text-decoration: none;
}

.inner-navigation a:hover {
  color: #000;
  text-decoration: underline;
}


$grid-columns: 24;

@import "../../node_modules/bootstrap/scss/bootstrap";
